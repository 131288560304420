<script setup>
import { animate } from 'motion'

const { animateLerp } = useReactiveLerp()

const props = defineProps({
    animation: {
        type: [Boolean, Array, Object],
        required: false,
        default: () => ({ transform: ['translateY(-100px) translateZ(0)', 'translateY(100px) translateZ(0)'] }),
    },
    optionsDefaults: {
        type: Object,
        required: false,
        default: () => ({
            duration: 1, easing: 'linear', fill: 'both', composite: 'accumulate', allowWebkitAcceleration: true,
        }),
    },
    options: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    once: { type: Boolean, required: false, default: false },
    enter: {
        type: [Boolean, Array, Object],
        required: false,
        default: () => ({ opacity: [0, 1], transform: ['translateY(-16px) translateZ(0)', 'translateY(0px) translateZ(0)'] }),
    },
    enterOptionsDefaults: {
        type: Object,
        required: false,
        default: () => ({
            duration: 1, easing: [0, 0.48, 0.38, 1], fill: 'both', composite: 'replace', allowWebkitAcceleration: true,
        }),
    },
    enterOptions: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    enterOnce: { type: Boolean, required: false, default: false },
    leave: { type: [Boolean, Array, Object], required: false, default: false },
    leaveOptionsDefaults: {
        type: Object,
        required: false,
        default: () => ({
            duration: 1, easing: [0, 0.48, 0.38, 1], fill: 'both', composite: 'replace', allowWebkitAcceleration: true,
        }),
    },
    leaveOptions: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    leaveOnce: { type: Boolean, required: false, default: false },
    invisibleClass: { type: String, required: false, default: 'parallax-child-visible parallax-invisible' },
    visibleClass: { type: String, required: false, default: 'parallax-child-visible parallax-visible' },
    appear: { type: Boolean, required: false, default: true },
    offset: { type: Number, required: false, default: 0 },
    classOnce: { type: Boolean, required: false, default: false },
    lerp: { type: Number, required: false, default: 0 },
    enterLeaveWrapperClasses: { type: String, required: false, default: '' },
})

const attrs = useAttrs()
const cssClasses = ref('')
if (props.appear && attrs.class) {
    cssClasses.value = [...attrs.class.split(' '), ...props.invisibleClass.split(' ')].join(' ')
}
const root = ref(null)
const enterLeaveRoot = ref(null)
const parallaxAnimation = ref(null)
const parallaxAnimationOptions = ref({})
const enterAnimation = ref(null)
const enterAnimationOptions = ref({})
const leaveAnimation = ref(null)
const leaveAnimationOptions = ref({})
const done = ref(false)
const enterDone = ref(false)
const leaveDone = ref(false)
const classDone = ref(false)

const visibility = inject('visibility')

const render = (currentProgress) => {
    if (!isServer()) {
        if (parallaxAnimation.value && !done.value) {
            const activeTime = (parallaxAnimationOptions.value.duration * currentProgress) - props.offset
            parallaxAnimation.value.currentTime = activeTime
            // parallaxAnimation.value.commitStyles();

            if (props.once && parallaxAnimation.value.currentTime >= activeTime) {
                done.value = true
            }
        }
    }
}

const enter = () => {
    if (!isServer()) {
        if (enterAnimation.value && (!props.enterOnce || (props.enterOnce && !enterDone.value))) {
            enterAnimation.value.play()

            if (props.enterOnce) {
                enterDone.value = true
            }
        }

        if (!classDone.value) {
            if (props.invisibleClass) {
                root.value.classList.remove(...props.invisibleClass.split(' '))
            }
            if (props.visibleClass) {
                root.value.classList.add(...props.visibleClass.split(' '))
            }

            if (props.classOnce) {
                classDone.value = true
            }
        }
    }
}

const leave = () => {
    if (!isServer()) {
        if (leaveAnimation.value && (!props.leaveOnce || (props.leaveOnce && !leaveDone.value))) {
            leaveAnimation.value.play()

            if (props.leaveOnce) {
                leaveDone.value = true
            }
        }

        if (!classDone.value) {
            if (props.invisibleClass) {
                root.value.classList.add(...props.invisibleClass.split(' '))
            }
            if (props.visibleClass) {
                root.value.classList.remove(...props.visibleClass.split(' '))
            }
        }
    }
}

const unwatchProgress = watch(visibility.progress, (currentProgress) => {
    if (props.lerp) {
        animateLerp(currentProgress, props.lerp, render)
    }
    else {
        render(currentProgress)
    }
    if (done.value) {
        unwatchProgress()
    }
})

const unwatchVisibility = watch(visibility.visible, (currentVisibility) => {
    if (currentVisibility) {
        enter()

        if (props.invisibleClass) {
            root.value.classList.remove(...props.invisibleClass.split(' '))
        }
        if (props.visibleClass) {
            root.value.classList.add(...props.visibleClass.split(' '))
        }

        if (props.classOnce) {
            unwatchVisibility()
        }
    }
    else {
        leave()

        if (props.invisibleClass) {
            root.value.classList.add(...props.invisibleClass.split(' '))
        }
        if (props.visibleClass) {
            root.value.classList.remove(...props.visibleClass.split(' '))
        }
    }
})

onMounted(() => {
    if (!isServer()) {
        if (props.enter) {
            enterAnimationOptions.value = { ...props.enterOptionsDefaults, ...props.enterOptions }
            enterAnimation.value = animate(enterLeaveRoot.value, props.enter, toRaw(enterAnimationOptions.value))
            enterAnimation.value.pause()
        }

        if (props.leave) {
            leaveAnimationOptions.value = { ...props.leaveOptionsDefaults, ...props.leaveOptions }
            leaveAnimation.value = animate(enterLeaveRoot.value, props.leave, toRaw(leaveAnimationOptions.value))
            leaveAnimation.value.pause()
        }

        if (props.animation) {
            parallaxAnimationOptions.value = { ...props.optionsDefaults, ...props.options }
            parallaxAnimation.value = animate(root.value, props.animation, toRaw(parallaxAnimationOptions.value))
            parallaxAnimation.value.pause()
        }
    }
})

onBeforeUnmount(() => {
    unwatchProgress()
})
</script>

<template>
    <div
        ref="root"
        :class="cssClasses"
        class="backface-hidden"
    >
        <div
            ref="enterLeaveRoot"
            class="parallax-child-enterleave backface-hidden"
            :class="enterLeaveWrapperClasses"
        >
            <slot />
        </div>
    </div>
</template>
